<template>
  <transition
    name="slide-fade"
    mode="out-in"
  >
    <div v-if="mReservation">
      <div
        v-if="showTabs"
        class="tabs is-boxed is-small"
      >
        <ul class="has-margin-top">
          <li :class="{ 'is-active': showSummary }">
            <a @click="setShowSummary">{{
              $t('Components.Reservation.ReservationSummary.List_Summary')
            }}</a>
          </li>
          <li
            v-if="mReservation.Notes.length > 0"
            class="has-badge-rounded"
            :class="{
              'is-active': showNotes,
            }"
            :data-badge="mReservation.Notes.length"
          >
            <a @click="setShowNotes"><span>{{
                $t('Components.Reservation.ReservationSummary.List_Notes')
              }}</span></a>
          </li>
          <li
            v-if="mReservation.Notes.length === 0"
            :class="{
              'is-active': showNotes,
            }"
          >
            <a @click="setShowNotes"><span>{{
                $t('Components.Reservation.ReservationSummary.List_Notes')
              }}</span></a>
          </li>
          <li
            v-if="nrOfOpenToDos > 0"
            class="has-badge-rounded"
            :class="{
              'is-active': showTodos,
            }"
            :data-badge="nrOfOpenToDos"
          >
            <a @click="setShowTodos">{{
              $t('Components.Reservation.ReservationSummary.List_ToDos')
            }}</a>
          </li>
          <li
            v-if="nrOfOpenToDos === 0"
            :class="{
              'is-active': showTodos,
            }"
          >
            <a @click="setShowTodos">{{
              $t('Components.Reservation.ReservationSummary.List_ToDos')
            }}</a>
          </li>
          <li
            v-if="mReservation.StatusId !== 3 && mReservation.HasInvoice === 0"
            :class="{ 'is-active': showAddOption }"
          >
            <a @click="setShowAddOption">{{
              $t('Components.Reservation.ReservationSummary.List_AddOption')
            }}</a>
          </li>
          <li
            v-if="mReservation.StatusId !== 3 && mReservation.HasInvoice === 0"
            :class="{ 'is-active': showAddSpace }"
          >
            <a @click="setShowAddSpace">{{
              $t('Components.Reservation.ReservationSummary.List_AddSpace')
            }}</a>
          </li>
        </ul>
      </div>

      <div
        v-if="showSummary"
        class="has-text-left is-size-7"
        key="reservationSummary"
      >
        <section class="section is-small">
          <div class="">
            <article
              v-if="nrOfOpenToDos > 0 && showTodoWarning"
              class="message is-small is-warning has-margin-top"
            >
              <div class="message-header">
                <p>
                  {{
                    $t(
                      'Components.Reservation.ReservationSummary.Message_TitleOpenToDos'
                    )
                  }}
                </p>
                <button
                  class="delete is-small is-warning"
                  aria-label="delete"
                  @click="showTodoWarning = false"
                ></button>
              </div>
              <div class="message-body">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                </span>
                <a @click="setShowTodos">
                  <span>{{ nrOfOpenToDos }}
                    {{
                      $t('Components.Reservation.ReservationSummary.Icon_Open')
                    }}
                    {{
                      nrOfOpenToDos > 1
                        ? $t(
                            'Components.Reservation.ReservationSummary.Message_SubtitleOpenToDoPlural'
                          )
                        : $t(
                            'Components.Reservation.ReservationSummary.Message_SubtitleOpenToDoSingle'
                          )
                    }}</span>
                </a>
              </div>
            </article>

            <div>
              <span
                v-if="mReservation.ReservationSetIds.length > 0"
                class="tag is-link"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'clone']" />
                </span>
                <span>{{
                  $t('Components.Reservation.ReservationSummary.Icon_PartOfSet')
                }}</span>
              </span>

              <span
                v-if="mReservation.ReservationSetIds.length === 0"
                class="tag is-disabled"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'clone']" />
                </span>
                <span>{{
                  $t(
                    'Components.Reservation.ReservationSummary.Icon_NotPartOfSet'
                  )
                }}</span>
              </span>

              <span
                v-if="mReservation.VoucherId > 0"
                class="tag is-primary"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'ticket-alt']" />
                </span>
                <span>{{ mReservation.VoucherName }}</span>
              </span>

              <span
                v-if="mReservation.VoucherId === 0"
                class="tag is-disabled"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'ticket-alt']" />
                </span>
                <span>{{
                  $t('Components.Reservation.ReservationSummary.Icon_NoVoucher')
                }}</span>
              </span>

              <span
                v-if="mReservation.HasInvoice > 0"
                class="tag is-warning"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'file-invoice']" />
                </span>
                <span>{{
                  $t(
                    'Components.Reservation.ReservationSummary.Icon_InvoiceCreated'
                  )
                }}</span>
              </span>

              <span
                v-if="mReservation.HasInvoice === 0"
                class="tag is-disabled"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'file-invoice']" />
                </span>
                <span>{{
                  $t('Components.Reservation.ReservationSummary.Icon_NoInvoice')
                }}</span>
              </span>
            </div>

            <table class="table is-fullwidth is-narrow">
              <tbody>
                <tr>
                  <td></td>
                  <td>#{{ mReservation.Id }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span class="is-size-7 has-text-weight-light is-italic has-text-grey">{{ $t('General.Created-on') }}
                      {{
                        mReservation.CreatedOn
                          | convertTimeStampToIsoDateTimeString
                          | dateFormat($i18n.locale, 'longDateFormat')
                      }}
                      {{
                        (
                          $t('General.On') +
                          ' ' +
                          $t('General.Channel')
                        ).toLowerCase()
                      }}
                      {{ mReservation.ChannelName }}</span>

                    <span
                      v-if="terms"
                      class="is-size-7 has-text-weight-light is-italic has-text-grey"
                    >, Terms: {{ terms.Version }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    <span
                      v-if="
                        mReservation.StatusId === 0 ||
                        mReservation.StatusId === 1
                      "
                      class="icon has-text-danger"
                    >
                      <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                    </span>
                    <span>{{ mReservation.StatusId | getStatusName }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    {{
                      $t('Components.Reservation.ReservationSummary.Table_Date')
                    }}
                  </td>
                  <td>
                    {{
                      mReservation.StartDate
                        | parseIsoDateStringToDate
                        | dateFormat
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{
                      $t('Components.Reservation.ReservationSummary.Table_Time')
                    }}
                  </td>
                  <td>
                    {{ mReservation.StartMinutes | minutesToTime }} -
                    {{ mReservation.EndMinutes | minutesToTime }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{
                      $t('Components.Reservation.ReservationSummary.Table_Name')
                    }}
                  </td>
                  <td>{{ mReservation.Name }}</td>
                </tr>
                <tr>
                  <td>
                    {{
                      $t(
                        'Components.Reservation.ReservationSummary.Table_Company'
                      )
                    }}
                  </td>
                  <td>{{ mReservation.CompanyName }}</td>
                </tr>
                <tr>
                  <td>
                    {{
                      $t(
                        'Components.Reservation.ReservationSummary.Table_Booker'
                      )
                    }}
                  </td>
                  <td>{{ mReservation.ProfileName }}</td>
                </tr>
                <tr v-if="mReservation.VoucherId > 0">
                  <td>
                    {{
                      $t(
                        'Components.Reservation.ReservationSummary.Table_Voucher'
                      )
                    }}
                  </td>
                  <td>{{ mReservation.VoucherName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section
          v-if="viewType === 'full'"
          class="section is-small"
        >
          <div class="">
            <table class="table is-fullwidth is-narrow">
              <tbody>
                <tr
                  v-for="space in mReservation.Spaces"
                  :key="space.Id"
                >
                  <td>
                    <div>{{ space.SpaceNumber }} {{ space.SpaceName }}</div>
                    <div>
                      {{ space.StartMinutes | minutesToTime }} -
                      {{ space.EndMinutes | minutesToTime }}
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{{ space.Seats }} </span>
                      <span v-if="space.CalculationType === 0">{{
                        $t(
                          'Components.Reservation.ReservationSummary.Table_Seats'
                        )
                      }}</span>
                      <span v-if="space.CalculationType === 1">{{
                        $t(
                          'Components.Reservation.ReservationSummary.Table_People'
                        )
                      }}</span>
                    </div>
                    <div>
                      {{ space.SettingId | getSettingName }}
                    </div>
                  </td>
                  <td
                    width="60"
                    class="has-text-right"
                  >
                    {{
                      calculateDisplayPrice(
                        space.PricePerSeat,
                        space.TaxPercentage
                      ) | toCurrency
                    }}
                  </td>
                  <td
                    width="60"
                    class="has-text-right"
                  >
                    {{
                      calculateDisplayPrice(
                        space.PriceTotal,
                        space.TaxPercentage
                      ) | toCurrency
                    }}
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="showSubTotals">
                <tr>
                  <td colspan="3">
                    {{
                      $t(
                        'Components.Reservation.ReservationSummary.Table_Subtotal'
                      )
                    }}
                  </td>
                  <td
                    width="60"
                    class="has-text-right"
                  >
                    {{
                      calculateDisplayPrice(spaceTotalExcl, spaceTaxPercentage)
                        | toCurrency
                    }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </section>

        <section
          v-if="viewType === 'full'"
          class="section is-small"
        >
          <div class="">
            <table class="table is-fullwidth is-narrow">
              <tbody>
                <tr
                  v-for="option in mReservation.Options"
                  :key="option.Id"
                >
                  <td width="25">{{ option.Amount }}x</td>
                  <td>{{ option.Name }}</td>
                  <td
                    width="60"
                    class="has-text-right"
                  >
                    {{
                      calculateDisplayPrice(
                        option.PriceTotal,
                        option.TaxPercentage
                      ) | toCurrency
                    }}
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="showSubTotals">
                <tr>
                  <td colspan="2">
                    {{
                      $t(
                        'Components.Reservation.ReservationSummary.Table_Subtotal'
                      )
                    }}
                  </td>
                  <td class="has-text-right">
                    <span v-if="!showPriceInclTax">{{
                      optionsTotalExcl | toCurrency
                    }}</span>
                    <span v-if="showPriceInclTax">{{
                      optionsTotalIncl | toCurrency
                    }}</span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </section>

        <section
          v-if="viewType === 'full'"
          class="section is-small"
        >
          <div class="">
            <table class="table is-fullwidth is-narrow">
              <tbody>
                <tr>
                  <td>
                    {{
                      $t(
                        'Components.Reservation.ReservationSummary.Table_TotalExclTax'
                      )
                    }}
                  </td>
                  <td
                    width="60"
                    class="has-text-right"
                  >
                    {{ mReservation.TotalExclTax | toCurrency }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{
                      $t(
                        'Components.Reservation.ReservationSummary.Table_TotalInclTax'
                      )
                    }}
                  </td>
                  <td
                    width="60"
                    class="has-text-right"
                  >
                    {{ mReservation.TotalInclTax | toCurrency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <div
          v-if="showTabs"
          class="has-margin-bottom has-text-right"
        >
          <a
            @click="goToReservation()"
            class="button is-primary is-small"
          >{{
            $t(
              'Components.Reservation.ReservationSummary.Button_OpenReservation'
            )
          }}</a>
        </div>

        <div v-if="showComments">
          <p class="subtitle">
            {{
              $t('Components.Reservation.ReservationSummary.Subtitle_Comments')
            }}
          </p>

          <comment-item
            v-for="comment in mReservation.Comments"
            :key="comment.Id"
            :commentObj="comment"
            :comments="mReservation.Comments"
            name="reservationSummaryComments"
          />
        </div>
      </div>

      <div v-if="showNotes">
        <Notes
          :locationId="mReservation.LocationId"
          :type="'reservation'"
          :itemId="mReservation.Id"
          :notes="mReservation.Notes"
          @noteAdded="noteAdded"
          @noteDeleted="noteDeleted"
          @noteUpdated="noteUpdated"
        />
      </div>

      <div v-if="showTodos">
        <ToDos
          :locationId="mReservation.LocationId"
          :type="'reservation'"
          :itemId="mReservation.Id"
          :todos="mReservation.ToDos"
          viewType="small"
          @todoAdded="todoAdded"
          @todoDeleted="todoDeleted"
          @todoUpdated="todoUpdated"
        />
      </div>

      <div v-if="showAddOption">
        <p class="title">
          {{ $t('Components.Reservation.ReservationSummary.Title_AddOption') }}
        </p>

        <add-reservation-options
          v-if="showAddOption"
          :reservationId="mReservation.Id"
          :reservationSpaces="mReservation.Spaces"
          :endMinutes="mReservation.EndMinutes"
          :startMinutes="mReservation.StartMinutes"
          :hideSelectedItems="true"
          :isEmployee="true"
          @optionSelected="addReservationOption"
        />
      </div>

      <div v-if="showAddSpace">
        <p class="title">
          {{ $t('Components.Reservation.ReservationSummary.Title_AddSpace') }}
        </p>

        <check-availability
          :channelId="mReservation.ChannelId"
          :locationId="mReservation.LocationId"
          :meetingtypeId="mReservation.MeetingtypeId"
          :startDate="new Date(mReservation.StartDate)"
          :startTime="mReservation.StartMinutes"
          :endDate="new Date(mReservation.StartDate)"
          :endTime="mReservation.EndMinutes"
        />
      </div>

      <div v-if="showChangeSpace">
        <p class="title">
          {{
            $t('Components.Reservation.ReservationSummary.Title_ChangeSpace')
          }}
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

const AddReservationOptions = () =>
  import('@/components/Reservations/AddReservationOptions')
const CommentItem = () => import('@/components/Comments/CommentItem')
const CheckAvailability = () =>
  import('@/components/Availability/CheckAvailability')

const Notes = () => import('@/components/Notes/Notes')
const ToDos = () => import('@/components/ToDos/ToDos')

export default {
  components: {
    'add-reservation-options': AddReservationOptions,
    'comment-item': CommentItem,
    'check-availability': CheckAvailability,
    Notes,
    ToDos,
  },

  props: {
    reservation: {
      default: null,
      type: Object,
    },

    reservationId: {
      default: 0,
      type: Number,
    },

    showComments: {
      default: false,
      type: Boolean,
    },

    showPriceInclTax: {
      default: false,
      type: Boolean,
    },

    showSubTotals: {
      default: false,
      type: Boolean,
    },

    showTabs: {
      default: true,
      type: Boolean,
    },

    viewType: {
      default: 'full',
      type: String,
    },
  },

  data() {
    return {
      mReservation: JSON.parse(JSON.stringify(this.reservation)),
      mReservationId: this.reservationId,
      mShowPriceInclTax: this.showPriceInclTax,
      showAddOption: false,
      showAddSpace: false,
      showChangeSpace: false,
      showSummary: true,
      showTodoWarning: false,
      showNotes: false,
      showTodos: false,
      timestamp: new Date().getTime(),
    }
  },

  created() {
    if (!this.mReservation && this.mReservationId > 0) {
      this.getReservationData()
    } else {
      this.convertOldDataFormat()
    }

    EventBus.$on('optionSelected', (option) => {
      this.addReservationOption(option)
    })

    EventBus.$on('spaceSelected', (value) => {
      this.addReservationSpace(value)
    })
  },

  beforeDestroy() {
    this.mReservation = null
    this.mReservationId = 0

    EventBus.$off('optionSelected')
    EventBus.$off('spaceSelected')
  },

  computed: {
    terms() {
      let terms = null

      if (
        this.mReservation &&
        this.mReservation.ReservationTerms &&
        this.mReservation.ReservationTerms.BookingTerm
      ) {
        terms = this.mReservation.ReservationTerms.BookingTerm
      }

      return terms
    },

    hasUnits() {
      let self = this
      let hasUnits = false

      if (
        self.mReservation &&
        self.mReservation.Units &&
        self.mReservation.Units.length > 0
      ) {
        hasUnits = true
      }

      return hasUnits
    },

    spaceTaxPercentage() {
      let taxPercentage = 0

      if (this.mReservation && this.mReservation.Spaces) {
        let space = this.mReservation.Spaces[0]
        if (space) {
          taxPercentage = space.TaxPercentage
        }
      }

      return taxPercentage
    },

    spaceTotalExcl() {
      let total = 0

      if (this.mReservation && this.mReservation.Spaces) {
        for (let i = 0; i < this.mReservation.Spaces.length; i++) {
          let space = this.mReservation.Spaces[i]

          total = total + space.PriceTotal
        }
      }

      return total
    },

    optionsTotalExcl() {
      let total = 0

      if (this.mReservation) {
        for (let i = 0; i < this.mReservation.Options.length; i++) {
          let option = this.mReservation.Options[i]

          total = total + option.PriceTotal
        }
      }

      return total
    },

    optionsTotalIncl() {
      let total = 0

      if (this.mReservation) {
        for (let i = 0; i < this.mReservation.Options.length; i++) {
          let option = this.mReservation.Options[i]

          total =
            total + option.PriceTotal * ((100 + option.TaxPercentage) / 100)
        }
      }

      return total
    },

    nrOfOpenToDos() {
      let self = this
      let total = 0

      if (this.mReservation && this.mReservation.ToDos) {
        for (let i = 0; i < this.mReservation.ToDos.length; i++) {
          let todo = this.mReservation.ToDos[i]
          if (!todo.IsDone) {
            total++
          }
        }
      }

      if (total > 0) {
        self.showTodoWarning = true
      }

      return total
    },
  },

  methods: {
    calculateDisplayPrice(price, taxPercentage) {
      if (this.mShowPriceInclTax) {
        price = price * ((100 + taxPercentage) / 100)
      }

      return price
    },

    getReservationData() {
      reservationProvider.methods
        .getReservationById(this.mReservationId)
        .then((response) => {
          if (response.status === 200) {
            this.mReservation = response.data
            this.convertOldDataFormat()
          }
        })
    },

    convertOldDataFormat() {
      let self = this

      if (self.mReservation && self.mReservation.StartTime) {
        self.mReservation.StartDate =
          self.$options.filters.dateObjectIsoDateString(
            new Date(self.mReservation.StartTime)
          ) + 'T00:00:00'
      }

      if (self.mReservation && self.mReservation.EndTime) {
        self.mReservation.EndDate =
          self.$options.filters.dateObjectIsoDateString(
            new Date(self.mReservation.EndTime)
          ) + 'T00:00:00'
      }

      if (
        self.mReservation &&
        self.mReservation.Units &&
        self.mReservation.Units.length > 0
      ) {
        self.mReservation.Spaces = []
        self.mReservation.StartMinutes = 0
        self.mReservation.EndMinutes = 0

        for (let i = 0; i < self.mReservation.Units.length; i++) {
          let unit = self.mReservation.Units[i]

          let space = {
            Id: unit.Id,
            ReservationId: unit.ReservationId,
            SpaceId: unit.UnitId,
            SpaceName: unit.UnitName,
            Seats: unit.Seats,
            SettingId: unit.SettingId,
            SettingName: unit.SettingName,
            StartDate: unit.StartDate,
            EndDate: unit.EndDate,
            CalculationType: 0,
            CurrencyId: unit.CurrencyId,
            PricePerSeat: unit.PricePerItem,
            PriceTotal: unit.PriceTotalExclTax,
            TaxId: unit.TaxId,
            TaxPercentage: unit.TaxPercentage,
          }

          if (unit.hasOwnProperty('StartMinutes')) {
            space.StartMinutes = unit.StartMinutes
          }
          if (unit.hasOwnProperty('EndMinutes')) {
            space.EndMinutes = unit.EndMinutes
          }

          if (unit.hasOwnProperty('StartTimeStamp')) {
            space.StartMinutes = self.$options.filters.timeToMinutes(
              self.$options.filters.convertTimeStampToIsoDateTimeString(
                unit.StartTimeStamp
              )
            )
          }
          if (unit.hasOwnProperty('EndTimeStamp')) {
            space.EndMinutes = self.$options.filters.timeToMinutes(
              self.$options.filters.convertTimeStampToIsoDateTimeString(
                unit.EndTimeStamp
              )
            )
          }

          self.mReservation.Spaces.push(space)
        }
      }

      let minStartMinutes = 0
      let maxEndMinutes = 1440

      for (let i = 0; i < self.mReservation.Spaces.length; i++) {
        let space = self.mReservation.Spaces[i]

        if (space.StartMinutes > minStartMinutes) {
          minStartMinutes = space.StartMinutes
        }

        if (space.EndMinutes < maxEndMinutes) {
          maxEndMinutes = space.EndMinutes
        }
      }

      self.mReservation.StartMinutes = minStartMinutes
      self.mReservation.EndMinutes = maxEndMinutes

      for (let i = 0; i < self.mReservation.Options.length; i++) {
        let option = self.mReservation.Options[i]

        if (
          option.hasOwnProperty('InternalName') &&
          option.InternalName.length > 0
        ) {
          self.mReservation.Options[i].Name = option.InternalName
        }
        if (
          option.hasOwnProperty('OptionInternalName') &&
          option.OptionInternalName.length > 0
        ) {
          self.mReservation.Options[i].Name = option.OptionInternalName
        }
        if (option.hasOwnProperty('PriceTotalExclTax')) {
          self.mReservation.Options[i].PriceTotal = option.PriceTotalExclTax
        }
      }

      if (self.mReservation.hasOwnProperty('TotalExcl')) {
        self.mReservation.TotalExclTax = self.mReservation.TotalExcl
      }

      if (self.mReservation.hasOwnProperty('TotalIncl')) {
        self.mReservation.TotalInclTax = self.mReservation.TotalIncl
      }
    },

    addReservationOption(option) {
      let self = this

      let criteria = {
        ReservationId: self.mReservationId,
        OptionId: option.OptionId,
        Amount: option.Amount,
        CurrencyId: option.CurrencyId,
        PricePerItem: option.PricePerItem,
        PriceTotal: option.PriceTotal,
        TaxId: option.TaxId,
        Crc: option.Crc,
        RequiredItem: option.RequiredItem,
        SpaceId: option.SpaceId,
        SelectedTime: option.SelectedTime,
      }

      reservationProvider.methods
        .addReservationOption(self.mReservationId, criteria)
        .then((response) => {
          if (response.status === 200) {
            self.mReservation = response.data
          }
        })
    },

    addReservationSpace(space) {
      let self = this

      let criteria = {
        ReservationId: self.mReservationId,
        Seats: space.Amount,
        CurrencyId: space.CurrencyId,
        PricePerItem: space.PricePerItem,
        PriceTotal: space.PriceTotal,
        TaxId: space.TaxId,
        Crc: space.Crc,
        RequiredItem: space.RequiredItem,
        SpaceId: space.SpaceId,
        StartDate: space.StartDate,
        StartMinuts: space.StartMinutes,
        EndDate: space.EndDate,
        EndMinutes: space.EndMinutes,
      }

      reservationProvider.methods
        .addReservationSpace(self.mReservationId, criteria)
        .then((response) => {
          if (response.status === 200) {
            self.mReservation = response.data
          }
        })

      //self.$console.log(space)
    },

    todoAdded(todo) {
      this.mReservation.ToDos.push(todo)
    },

    todoDeleted(todo) {
      let index = this.mReservation.ToDos.findIndex((t) => t.Id === todo.Id)
      if (index > -1) {
        this.mReservation.ToDos.splice(index, 1)
      }
    },

    todoUpdated(todo) {
      let index = this.mReservation.ToDos.findIndex((t) => t.Id === todo.Id)
      this.$set(this.mReservation.ToDos, index, todo)
    },

    noteAdded(note) {
      this.mReservation.Notes.push(note)
    },

    noteDeleted(note) {
      let index = this.mReservation.Notes.findIndex((t) => t.Id === note.Id)
      if (index > -1) {
        this.mReservation.Notes.splice(index, 1)
      }
    },

    noteUpdated(note) {
      let index = this.mReservation.Notes.findIndex((t) => t.Id === note.Id)
      this.$set(this.mReservation.Notes, index, note)
    },

    goToReservation() {
      this.$router.push({
        name: `reservations-list-detail`,
        params: { reservationId: this.mReservation.Id },
      })
    },

    setShowSummary() {
      this.setVisibleItem('summary')
    },

    setShowNotes() {
      this.setVisibleItem('notes')
    },

    setShowTodos() {
      this.setVisibleItem('todos')
    },

    setShowAddOption() {
      this.setVisibleItem('addOption')
    },

    setShowAddSpace() {
      this.setVisibleItem('addSpace')
    },

    setShowChangeSpace() {
      this.setVisibleItem('changeSpace')
    },

    setVisibleItem(item) {
      let self = this

      self.showNotes = item === 'notes'
      self.showTodos = item === 'todos'
      self.showAddOption = item === 'addOption'
      self.showAddSpace = item === 'addSpace'
      self.showChangeSpace = item === 'changeSpace'
      self.showSummary = item === 'summary'
    },
  },
}
</script>
